import { Routes, Route } from "react-router-dom";
import Main from "Views/Main/Main";
import ApplicantHome from "Views/Applicant/ApplicantHome";
import AgencyHome from "Views/Agency/AgencyHome";
import AgencySignup from "Views/Agency/AgencySignup";
import ApplicantSignup from "Views/Applicant/ApplicantSignup";
import data from "./Views/Applicant/jobdata.json";
import JobPost from "Views/Agency/Job/JobPost";

function App() {
  console.log(data);

  const slugify = (str) =>
    str
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, "")
      .replace(/[\s_-]+/g, "-")
      .replace(/^-+|-+$/g, "");

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Main />}></Route>
        <Route path="/applicant-home" element={<ApplicantHome />}></Route>
        <Route path="/agency-home" element={<AgencyHome />}></Route>
        <Route path="/new-applicant" element={<ApplicantSignup />}></Route>
        <Route path="/new-agency" element={<AgencySignup />}></Route>
        {data.map((job) => {
          const url = slugify(job.title + job.id);
          console.log(url);
          return (
            <Route path={`/${url}`} element={<JobPost job={job} />}></Route>
          );
        })}
      </Routes>
    </div>
  );
}

export default App;
