import React, { useState } from "react";
import * as MdIcons from "react-icons/md";
import * as BsIcons from "react-icons/bs";

import "./jobcard.css";
import { Link } from "react-router-dom";

const JobCard = ({ job }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  function toggleCard() {
    setIsExpanded(!isExpanded);
  }

  const slugify = (str) =>
    str
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, "")
      .replace(/[\s_-]+/g, "-")
      .replace(/^-+|-+$/g, "");

  return (
    <div className={isExpanded ? "show-full job-card" : "job-card"}>
      <div className="inner-job-container">
        <p className="due-date">Applications due by {job.dueDate}</p>
        <h2 className="job-title">{job.title}</h2>
        <p className="job-summary">{job.summary1}</p>
        <p className="job-summary">{job.summary2}</p>
        <span className="location-pin">
          {job.locations.map((location) => {
            return (
              <span>
                <MdIcons.MdLocationPin /> {location}
              </span>
            );
          })}
        </span>

        <div className="card-btn-container">
          <Link className="btn" to={`/${slugify(job.title + job.id)}`}>
            Learn More
          </Link>
          <Link className="btn success" to={"/apply"}>
            Apply
          </Link>
        </div>
      </div>
      <span
        onClick={toggleCard}
        className={isExpanded ? "expanded chevron" : "chevron"}
        style={{ fontSize: "24px", justifySelf: "flex-end" }}
      >
        <BsIcons.BsChevronCompactDown />
      </span>
    </div>
  );
};

export default JobCard;
