import Keyword from "Components/Keyword";
import React from "react";
import "./jobpost.css";
import * as MdIcons from "react-icons/md";

const JobPost = ({ job }) => {
  console.log(job);
  return (
    <div className="jobpost-container">
      <h1>{job.title}</h1>
      <p>Applications due: {job.dueDate}</p>
      <h3>Location:</h3>
      <span className="location-pin">
        {job.locations.map((location) => {
          return (
            <span>
              <MdIcons.MdLocationPin /> {location}
            </span>
          );
        })}
      </span>
      <h3>Agency:</h3>
      <p>{job.agency}</p>
      <h3>Salary</h3>
      <p>{job.salary}</p>
      <h3>Estimated position dates: </h3>
      <p>
        {job.start} - {job.end}
      </p>
      <h3>Description:</h3>
      <p className="job-description">{job.description}</p>
      <h3>Keywords:</h3>
      {job.keywords.map((keyword) => {
        return <Keyword keyword={keyword} />;
      })}
      <h3>Qualifications:</h3>
      <ul>
        {job.qualifications.map((qualifications) => {
          return <li>{qualifications}</li>;
        })}
      </ul>
      <h3>Responsibilities:</h3>
      <ul>
        {job.responsibilities.map((responsibilities) => {
          return <li>{responsibilities}</li>;
        })}
      </ul>
      <h4>Get more information here:</h4>
      <a href={job.website}>
        <p>{job.website}</p>
      </a>
    </div>
  );
};

export default JobPost;
