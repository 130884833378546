import React, { useState, useId } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./login.css";

const Login = ({ loginType }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const onSubmit = () => {
    loginType === "agency"
      ? navigate("/agency-home")
      : navigate("/applicant-home");
  };

  const id = useId();

  return (
    <div className="form-container">
      <h2>{`For ${loginType === "agency" ? "Agencies" : "Applicants"}`}</h2>
      <div className="form-group">
        <span className="login-element">
          <label className="nowrap" htmlFor={`username-${id}`}>
            Username:
          </label>
          <input
            type="text"
            id={`username-${id}`}
            placeholder="Enter Username"
            value={username}
            required
            onChange={(e) => setUsername(e.target.value)}
          ></input>
        </span>
        <span className="login-element">
          <label className="nowrap" htmlFor={`password-${id}`}>
            Password:
          </label>
          <input
            type="password"
            id={`password-${id}`}
            placeholder="Enter Password"
            value={password}
            required
            onChange={(e) => setPassword(e.target.value)}
          ></input>
        </span>
      </div>

      <button className="login-btn" type="button" onClick={onSubmit}>
        Log In
      </button>

      <p>
        New to MINRC Job Portal?{" "}
        <Link to={`new-${loginType}`}>Create a profile.</Link>
      </p>
    </div>
  );
};

export default Login;
