import React from "react";
import NavBar from "Components/NavBar";
import "./ApplicantHome.css";
import data from "./jobdata.json";
import JobCard from "Components/JobCard";
import FilterPanel from "Components/FilterPanel";

const ApplicantHome = () => {
  console.log(data);

  return (
    <>
      <NavBar />
      <h1>MINRC Job Board</h1>
      <div className="job-view">
        {/* <Search /> */}
        <div className="job-container">
          {data.map((job) => {
            return <JobCard key={job.id} job={job} />;
          })}
        </div>
        <div className="filter-container">
          <FilterPanel />
        </div>
      </div>
    </>
  );
};

export default ApplicantHome;
